export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'dashbord',
        route: 'dashboard',
        icon: 'HomeIcon',
      },
      {
        title: 'Suppliers',
        route: 'suppliers',
        icon: 'FileIcon',
      },
      {
        title: 'Products',
        route: 'products',
        icon: 'FileIcon',
      },
      {
        title: 'Delivery Notes',
        icon: 'CheckSquareIcon',
        children: [
          {
            title: 'List Delivery Notes',
            route: 'delivery-notes',
          },
          {
            title: 'Add Delivery Note',
            route: 'create-delivery-note',
          },
        ],
      },
    ],
  },
]
